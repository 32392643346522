<template>
  <div v-if="isMounted">
    <vs-button class="sm:px-base px-4 mb-4" @click="scrollToElement({behavior: 'smooth'})">Ir al final</vs-button>
    <vs-button v-if="downloadButtonEnabled" class="sm:px-base px-4 ml-2 mb-4" @click="downloadPdfContract">Descargar</vs-button>

    <!-- contratos para firmar y ya firmados -->
    <vx-card class="mb-5" title-color="primary" >
      <latest-terms-contract v-if="termsActive" />
      <latest-privacy-contract v-if="privacyActive" />
      <latest-investor-merchant-contract v-if="merchantActive" :fill-user-data="true" :only-cover-page="onlyCoverPage" :signed-date="signatureFormatDate(currentMetaData.acceptance_date)"/>
      <latest-investor-risks-contract v-if="risksActive && currentContract.isSigned" />
      <latest-investor-direct-billing-contract v-if="directBillingActive && currentContract.isSigned" />
    </vx-card>
    <!-- constancia de riesgos para contestar -->
    <investor-risk-acknowledgment v-if="risksActive && !currentContract.isSigned" />

    <div class="sign-area">
      <vx-card v-if="showSignature" class="mb-2" title-color="primary" title="TU FIRMA">
        <h5>Datos de la firma:</h5>
        <p class="my-2"><span class="bold">Nombre del documento: </span> {{currentMetaData.document}}</p>
        <p class="my-2"><span class="bold">Versión del documento: </span> {{currentMetaData.version}}</p>
        <p class="my-2"><span class="bold">Fecha de aceptación: </span> {{signatureFormatDate(currentMetaData.acceptance_date)}}</p>
        <p class="my-2"><span class="bold">Agente: </span> {{currentMetaData.agent}}</p>
        <p class="my-2"><span class="bold">IP: </span> {{currentMetaData.ip}}</p>
        <p class="my-2"><span class="bold">Correo electrónico: </span> {{currentMetaData.email}}</p>
        <p class="my-2" v-if="currentMetaData.geolocation != null && currentMetaData.geolocation != '--'"><span class="bold">Ubicación: </span> {{currentMetaData.geolocation}}</p>
        <h5 class="mt-5">Cadena de firma electrónica:</h5>
        <p class="text-break text-xs my-2">{{currentSignature}}</p>
      </vx-card>
      <vs-button v-else-if="(currentContract.type !== 1 && currentContract.type !== 2) && !risksActive && !directBillingActive && !currentContract.isSigned" class="sm:px-base px-4" @click="signContract">Firmar contrato</vs-button>
    </div>
  </div>
</template>

<script>
import LatestTermsContract from "@views/share/contracts/TermsContract/LatestTermsContract.vue";
import LatestPrivacyContract from "@views/share/contracts/PrivacyContract/LatestPrivacyContract.vue";
import LatestInvestorMerchantContract from "@views/investor/contracts/LatestInvestorMerchantContract.vue";
import LatestInvestorRisksContract from "@views/investor/contracts/LatestInvestorRisksContract";
import LatestInvestorDirectBillingContract from "@views/investor/contracts/LatestInvestorDirectBillingContract";
import InvestorRiskAcknowledgment from "@views/investor/contracts/InvestorRiskAcknowledgment";
import dateHelper from "@mixins/dateHelper";
export default {
  name: "ContractInfoPage",
  props: {
    type: { required: true },
    downloadButtonEnabled: { required: false, default: false },
    onlyCoverPage: { required: false, default:false }
  },
  mixins: [dateHelper],
  components: {
    LatestTermsContract,
    LatestPrivacyContract,
    LatestInvestorMerchantContract,
    LatestInvestorRisksContract,
    LatestInvestorDirectBillingContract,
    InvestorRiskAcknowledgment
  },
  data(){
    return {
      currentContract:null,
      currentLocation:null,
      allContracts: [],
      termsActive: false,
      privacyActive: false,
      merchantActive: false,
      risksActive: false,
      directBillingActive: false,
      showSignature: false,
      currentSignature: null,
      isMounted: false,
      currentMetaData: {
        acceptance_date: null,
        agent: null,
        geolocation:null,
        alias: null,
        email: null,
        document: null,
        ip: null,
        password: null,
        validity_date: null,
        version: null,
      },
      locationOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      }
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getContractsData();
    this.isMounted = true;
    if('geolocation' in navigator) {
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    }
    this.showLoading(false)
  },
  watch: { 
    type: async function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getContractsData();
      }
    }
  },
  methods: {
    async getContractsData(){
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getContracts`);
        const defaultContracts = res.data.default;
        const invContracts = res.data.investor;
        defaultContracts.forEach(df => {
          const iinv = invContracts.find(f => f.contract.type === df.type);
          if(iinv != null){
            this.allContracts.push({
              name: df.name,
              type: df.type,
              isSigned: (iinv.signature != null),
              signature: iinv.signature ?? null,
              metadata: iinv.metadata ?? null,
            })
          }
          else {
            this.allContracts.push({
              name: df.name,
              type: df.type,
              isSigned: false,
              signature: null,
              metadata: null
            })
          }
        });
        this.currentContract = this.allContracts.find(f => f.type === this.type);
        if(this.currentContract != null){
            this.resetValues();
            if(this.currentContract.isSigned){
              this.currentSignature = this.currentContract.signature;
              this.currentMetaData = this.currentContract.metadata;
              this.showSignature=true;
            }
            switch (this.currentContract.type) {
              case 1:
                this.termsActive = true;                  
                break;
              case 2:
                this.privacyActive = true;                  
                break;
              case 3:
                this.merchantActive = true;                  
                break;
              case 15:
                this.risksActive = true;                  
                break;
              case 19:
                this.directBillingActive = true;                  
                break;
              default:
                break;
            }
        }else{
          window.location.href = '/inversionista/perfil/contratos';
        }
      }
      catch (e) {
      }
    },
    resetValues(){
      this.showSignature=false;
      this.termsActive = false;
      this.privacyActive = false;
      this.merchantActive = false;
      this.risksActive = false;
      this.currentLocation = null;
    },
    async signContract() {
      this.showLoading(true);
      try {
        let geolocation = null;
        if(this.currentLocation){
          geolocation = "Lat: "+this.currentLocation.latitude+", Long "+this.currentLocation.longitude;
        }
        const payload = { contractType: this.currentContract.type, geolocation: geolocation}
        const res = await axios.post(`/api/v2/investor/${this.InvestorId}/signContract`, payload);
        if(res){
          this.showLoading(false);
          this.$router.go();
        }
      } catch (error) {
        this.showLoading(false);
        this.errorNotif("Aviso", "Ha ocurrido un error", 4000);
      }
    },
    async onSuccessLocation(pos){
      this.currentLocation = pos.coords;
    },
    onErrorLocation(err){
      // console.log(err);
    },
    scrollToElement(options) {
      const el = this.$el.getElementsByClassName('sign-area')[0]; 
      if (el) {
        el.scrollIntoView(options);
      }
    },
    async downloadPdfContract(){
      try {
        let url ="";
        if (this.onlyCoverPage) {
          url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadPdfContractCover/${this.currentContract.type}?access_token=${this.AccessToken}&2fa_restart_cookie=true`;
        } else {
          url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadPdfContract/${this.currentContract.type}?access_token=${this.AccessToken}&2fa_restart_cookie=true`;
        }
        window.open(url, "_blank");
      }
      catch (e) {
        console.log(e);
        this.warn(e)
      }
    },
  }
}
</script>
